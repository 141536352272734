import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"]
  static classes = ["toggled"]

  toggle() {
    this.elementTargets.forEach(e => {
      e.classList.toggle(this.toggledClass)
      console.log(e)
    })
  }
}
